<template>
  <v-container fluid>
    <v-flex xs12>
      <v-layout v-if="loading">
        <v-spacer />
        <v-flex sheet xs12>
          <v-progress-linear :indeterminate="true" height="2" />
        </v-flex>
        <v-spacer />
      </v-layout>
      <v-layout
        v-if="!loading && !(isSDR || isSDRManager || (isRA && !isL1Manager))"
        justify-end
        justify-space-between
      >
        <v-spacer />
        <v-btn-toggle
          :value="showing"
          active-class="active-button"
          class="elevation-0"
          @change="toggleView"
        >
          <v-btn
            :color="showing === 'dashboard' ? 'primary' : ''"
            class="elevation-0"
            solo
            value="dashboard"
          >
            Dashboard
          </v-btn>
          <v-btn
            :color="showing === 'leaderboard' ? 'primary' : ''"
            class="elevation-0"
            solo
            value="leaderboard"
          >
            Leaderboard
          </v-btn>
        </v-btn-toggle>
      </v-layout>
      <v-layout>
        <v-flex><br /></v-flex>
      </v-layout>
      <v-layout v-if="!loading">
        <v-flex v-if="showing === 'dashboard'" xs12>
          <v-layout>
            <v-flex sheet xs12>
              <v-layout row wrap>
                <v-flex xs2>
                  <h1>My Profit</h1>
                </v-flex>
                <v-flex xs8 />
                <v-flex xs2>
                  <CRSelect
                    id="period"
                    v-model="period"
                    :items="periods"
                    label="Select Period"
                    @input="calculateProfits"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex text-xs-center xs3>
                  <div>Reservations Total</div>
                  <div class="headline">
                    {{ currencyFilter(profitstotalReservationAmount) }}
                  </div>
                  <div><br /></div>
                  <div>Reservations Count</div>
                  <div class="headline">
                    {{ profitstotalReservationCount }}
                  </div>
                </v-flex>
                <v-flex
                  v-if="!(isSDR || isSDRManager || (isRA && !isL1Manager))"
                  text-xs-center
                  xs3
                >
                  <div>Referrals Total</div>
                  <div class="headline">
                    {{ currencyFilter(profitstotalReferralAmount) }}
                  </div>
                  <div><br /></div>
                  <div>Referrals Count</div>
                  <div class="headline">
                    {{
                      profitstotalAcceptedReferrals +
                      profitstotalPendingReferrals
                    }}
                  </div>
                </v-flex>
                <v-flex
                  v-if="!(isSDR || isSDRManager || (isRA && !isL1Manager))"
                  text-xs-center
                  xs3
                >
                  <div>Profit</div>
                  <div
                    :class="
                      profitsreservationReferralDiffAmount <= 0
                        ? 'headline red-text'
                        : 'headline green-text'
                    "
                  >
                    {{ currencyFilter(profitsreservationReferralDiffAmount) }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <v-progress-circular
                    :color="referralPercentage > 0 ? 'green' : 'red'"
                    :rotate="360"
                    :size="200"
                    :value="referralPercentage"
                    :width="25"
                  >
                    {{ Number(referralPercentage || 0).toFixed(0) }}%
                  </v-progress-circular>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sheet xs12>
              <div><h1>My Unsold</h1></div>
              <br />
              <v-layout row wrap>
                <v-flex aggregate-row sheet xs12>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <span class="headline">
                        {{ unsoldtotalUnconvertedQuoteCount }}
                      </span>
                      <span class="subheading">Quotes</span>
                    </v-flex>
                    <v-flex xs8 />
                    <v-flex xs2>
                      <span class="headline">
                        {{ currencyFilter(unsoldtotalUnconvertedQuoteAmount) }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-if="!(isSDR || isSDRManager || (isRA && !isL1Manager))">
            <v-flex sheet xs12>
              <div><h1>My Pending</h1></div>
              <br />
              <v-layout row wrap>
                <v-flex aggregate-row sheet xs12>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <span class="headline">
                        {{ pendingtotalNonPendingAcceptedCount }}
                      </span>
                      <span class="subheading">Reservations</span>
                    </v-flex>
                    <v-flex xs8 />
                    <v-flex xs2>
                      <span class="headline">
                        {{
                          currencyFilter(pendingtotalNonPendingAcceptedAmount)
                        }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sheet xs12>
              <div><h1>My Sales</h1></div>
              <br />
              <v-layout row wrap>
                <v-flex aggregate-row sheet xs12>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <span class="headline">
                        {{ salestotalReservationCount }}
                      </span>
                      <span class="subheading">Sales</span>
                    </v-flex>
                    <v-flex xs8 />
                    <v-flex xs2>
                      <span class="headline">
                        {{ currencyFilter(salestotalReservationAmount) }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="showing === 'leaderboard'" xs12>
          <v-layout>
            <v-flex sheet xs12>
              <v-layout row wrap>
                <v-flex xs2>
                  <h1>Leaderboard</h1>
                </v-flex>
                <v-flex xs8 />
                <v-flex xs2>
                  <CRSelect
                    id="period"
                    v-model="period"
                    :items="periods"
                    label="Select Period"
                    @input="calculateProfits"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                v-for="(salesRep, salesRepIndex) in leaderboardData"
                :key="salesRepIndex"
                row
                wrap
              >
                <v-flex :class="leaderBoardRowClass(salesRepIndex)">
                  <v-layout align-center row>
                    <v-flex align-center justify-center layout xs1>
                      <img
                        v-if="salesRepIndex === 0"
                        class="first-place-icon"
                        src="@/assets/images/first_place.png"
                      />
                      <img
                        v-if="salesRepIndex === 1"
                        class="place-icon"
                        src="@/assets/images/second_place.png"
                      />
                      <img
                        v-if="salesRepIndex === 2"
                        class="place-icon"
                        src="@/assets/images/third_place.png"
                      />
                      <span
                        v-if="![0, 1, 2].includes(salesRepIndex)"
                        class="subheading"
                      >
                        {{ salesRepIndex + 1 }}
                      </span>
                    </v-flex>
                    <v-flex xs1>
                      <v-avatar class="the-avatar" size="60">
                        <img
                          v-if="setPhoto(salesRep)"
                          :class="
                            salesRepIndex !== 0
                              ? 'profile-picture'
                              : 'first-place-profile-picture'
                          "
                          :src="
                            setPhoto(salesRep) ||
                            '@/assets/images/default_profile.png'
                          "
                        />
                        <img
                          v-else
                          :class="
                            salesRepIndex !== 0
                              ? 'profile-picture'
                              : 'first-place-profile-picture'
                          "
                          src="@/assets/images/default_profile.png"
                        />
                      </v-avatar>
                    </v-flex>
                    <v-flex xs8>
                      <div v-if="salesRepIndex === 0" class="flex">
                        <strong>
                          <span class="headline white-text">
                            {{ salesRep.firstName }}
                            {{ salesRep.lastName }}
                          </span>
                        </strong>
                      </div>
                      <span v-else class="subheading">
                        {{ salesRep.firstName }}
                        {{ salesRep.lastName }}
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        v-if="salesRepIndex === 0"
                        class="headline white-text"
                      >
                        {{ salesRep.totalReservationCount }}
                        {{
                          salesRep.totalReservationCount > 1 ? 'Sales' : 'Sale'
                        }}
                      </span>
                      <span v-else class="headline">
                        {{ salesRep.totalReservationCount }}
                        {{
                          salesRep.totalReservationCount > 1 ? 'Sales' : 'Sale'
                        }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/currency'
import { baseUrl } from '@/utils/env'
import { authComputed } from '@/state/helpers'

export default {
  metaInfo() {
    return {
      title: 'My Sales Dashboard',
    }
  },
  data() {
    return {
      loading: false,
      user: {},
      showing: 'dashboard',
      profitstotalPendingReferrals: 0,
      profitstotalAcceptedReferrals: 0,
      profitstotalReferralAmount: 0,
      profitstotalReservationAmount: 0,
      profitstotalReservationCount: 0,
      profitsreservationReferralDiffAmount: 0,
      pendingtotalNonPendingAcceptedCount: 0,
      pendingtotalNonPendingAcceptedAmount: 0,
      salestotalReservationCount: 0,
      salestotalReservationAmount: 0,
      unsoldtotalUnconvertedQuoteCount: 0,
      unsoldtotalUnconvertedQuoteAmount: 0,
      leaderboardData: [],
      referralPercentage: 0,
      currencyFilter,
      period: [
        DateTime.local().startOf('week').toISODate(),
        DateTime.local().endOf('week').toISODate(),
      ],
      periods: [
        {
          text: 'Today',
          value: [
            DateTime.local().startOf('day').toISODate(),
            DateTime.local().endOf('day').toISODate(),
          ],
        },
        {
          text: 'This Week',
          value: [
            DateTime.local().startOf('week').toISODate(),
            DateTime.local().endOf('week').toISODate(),
          ],
        },
        {
          text: 'This Month',
          value: [
            DateTime.local().startOf('month').toISODate(),
            DateTime.local().endOf('month').toISODate(),
          ],
        },
        {
          text: 'Last Month',
          value: [
            DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
            DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
          ],
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.loading = true
    await this.calculateProfits()
    this.loading = false
  },
  methods: {
    leaderBoardRowClass(index) {
      if (index === 0) {
        return 'top-sales'
      }
      if (index % 2 === 0) {
        return 'row-highlight'
      }
      return ''
    },
    setPhoto(user) {
      const photo = user?.imagePath

      if (photo) {
        return `https://${baseUrl(null)}${photo}`
      }
      return null
    },
    toggleView(showing) {
      if (showing !== undefined) {
        this.showing = showing
      }
    },
    async calculateProfits() {
      const open = this.period[0]
      const close = this.period[1]
      const unsoldQuotes = await this.$store
        .dispatch('dashboard/unsoldQuotes', { startDate: open, endDate: close })
        .catch(() => false)
      const unsoldQuotesData = unsoldQuotes.data || {}
      Object.keys(unsoldQuotesData).forEach(
        (key) => (this[`unsold${key}`] = unsoldQuotesData[key])
      )
      const pendingReferrals = await this.$store
        .dispatch('dashboard/pendingReferrals', {
          startDate: open,
          endDate: close,
        })
        .catch(() => false)
      const pendingReferralsData = pendingReferrals.data || {}
      Object.keys(pendingReferralsData).forEach(
        (key) => (this[`pending${key}`] = pendingReferralsData[key])
      )
      const sales = await this.$store
        .dispatch('dashboard/sales', { startDate: open, endDate: close })
        .catch(() => false)
      const salesData = sales.data || {}
      Object.keys(salesData).forEach(
        (key) => (this[`sales${key}`] = salesData[key])
      )
      const profits = await this.$store
        .dispatch('dashboard/profits', { startDate: open, endDate: close })
        .catch(() => false)
      const profitsData = profits.data || {}
      Object.keys(profitsData).forEach(
        (key) => (this[`profits${key}`] = profitsData[key])
      )
      const leaderboard = await this.$store
        .dispatch('dashboard/leaderboard', { startDate: open, endDate: close })
        .catch(() => false)
      this.leaderboardData = leaderboard.data || []
      this.leaderboardData.sort((a, b) =>
        a.totalReservationCount > b.totalReservationCount ? -1 : 0
      )
      const diff =
        this.profitstotalReservationAmount - this.profitstotalReferralAmount
      this.referralPercentage =
        (diff / this.profitstotalReservationAmount) * 100
    },
  },
}
</script>

<style lang="scss">
h1 {
  font-size: 23px;
}

.green-text {
  color: $green;
}

.red-text {
  color: $red;
}

.white-text {
  color: $white;
}

.aggregate-row {
  background-color: $background-light;
}

.top-sales {
  background-image: url('~@/assets/images/first_place_bg.png');
  background-repeat: no-repeat;
}

.row-highlight {
  background-color: $highlight;
}

.first-place-icon {
  width: 80px;
  height: 80px;
}

.first-place-profile-picture {
  width: 60px;
  height: 60px;
  padding: 10px;
  margin-left: 1%;
}

.place-icon {
  width: 44px;
  height: 44px;
  padding: 4px;
  margin-left: 2%;
}

.profile-picture {
  width: 40px;
  height: 40px;
  padding: 10px;
}

::v-deep.v-btn--active {
  color: $white !important;
}

::v-deep .v-avatar img {
  object-fit: cover;
}
</style>
